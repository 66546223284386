/* eslint-disable no-undef */
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import { useAuthStore } from '../../store';

const getToken = () => {
	return localStorage.getItem("HMadminToken");
};

const API_URL = process.env.VUE_APP_API_URL || "http://localhost:3000/api";

const axiosInstance = axios.create({
	baseURL: API_URL
	// headers: {
	// 	"Content-Type": "application/json",
	// 	"Authorization": "Bearer " + token
	// }
});

axiosInstance.interceptors.request.use(config => {
	config.headers.common["Content-Type"] = "application/json";
	config.headers.common["Authorization"] = "Bearer " + getToken();
	return config;
});



axiosInstance.interceptors.response.use(
	function(response) {
		if (response.headers["authtoken"]) {
			const authStore = useAuthStore();
			// console.log('refresh with ', response.headers["authtoken"])
			authStore.refreshUserData(response.headers["authtoken"]);
			// localStorage.setItem("HMadminToken", response.headers["authtoken"]);
		}

		return response;
	},
	function(error) {
		let axios_error = error
		if (axios_error.response.status == 401) {
			// eslint-disable-next-line no-undef
			if(axios_error.config && axios_error.config.url && axios_error.config.url == "/login") {
				// if the 401 comes from /login then the dispatcher does not work, no idea wyhy
			}
			else	{
			router.push("/login");
			}
		} else if (![400, 422].includes(error.response.status)) {
			const toaster = createToaster({ position: 'top' });
			toaster.error("An error occured.\n<br/> Please contact the support.\n<br/>Error Message: '" +
					error.message +
					"'"
			);
		}
		return Promise.reject(axios_error);
	}
);
export default axiosInstance;
