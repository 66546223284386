import { defineStore } from 'pinia';
import VueJwtDecode from "vue-jwt-decode";


import API from "../js/backend/axios";

export const useAuthStore = defineStore({
	id: 'auth',
	state: () => ({
		// initialize state from local storage to enable user to stay logged in
		userdata: JSON.parse(localStorage.getItem("userData")) || {},
		token: localStorage.getItem("HMadminToken") || "",
		status: "o",
		hasLoadedOnce: false,
		loggedin: (typeof (localStorage.getItem("userData")) != 'string') ? false : true
	}),
	getters: {
		isAuthenticated: state => !!state.token,
		authStatus: state => state.status,
		hasLoggedOut: state => state.loggedout,
		getUserdata: state => state.userdata,
	},
	actions: {
		async authRequest(inc_user) {
			try {
				const  resp = await API.post("/login", inc_user)
				// console.log(resp)
				this.token = resp.data.jwt;
				localStorage.setItem("HMadminToken", this.token);
				// console.log(resp.data)
				const decodedToken = VueJwtDecode.decode(resp.data.jwt);
				console.log(decodedToken)
				this.userdata = decodedToken.context.user;
				localStorage.setItem("userData", JSON.stringify(this.userdata)); // store the token in localstorage
				this.status = "success";
				this.loggedin = true;
				this.hasLoadedOnce = true;
				return resp;

			}
			catch(err) {
				return {'Error': err.message}
			}
		},

		async authRefresh()  {
			try {
				const  resp = await API.post("/refresh", inc_user)
				console.log(resp)
				this.token = resp.data.jwt;
				localStorage.setItem("HMadminToken", this.token);
				const decodedToken = VueJwtDecode.decode(resp.data.jwt);
				this.userdata = decodedToken.context.user;
				localStorage.setItem("userData", JSON.stringify(this.userdata)); // store the token in localstorage
				this.status = "success";
				this.loggedin = true;
				this.hasLoadedOnce = true;
				return resp;

			}
			catch(err) {
				return {'Error': err.message}
			}
		},

		refreshUserData(tokenstring) {
			localStorage.setItem("HMadminToken", tokenstring);
			const decodedToken = VueJwtDecode.decode(tokenstring);
			// console.log(decodedToken)
			this.userdata = decodedToken.context.user;
			localStorage.setItem("userData", JSON.stringify(this.userdata)); // store the token in localstorage
			this.status = "success";
			this.loggedin = true;
			this.hasLoadedOnce = true;
		},

		authLogout() {
			console.log("Logged out");
			localStorage.removeItem("HMadminToken");
			localStorage.removeItem("userData");
			this.token = "";
			this.userdata = {};
			this.loggedin = false;
		}
	}
});
