import { defineStore } from 'pinia';
import API from "../js/backend/axios";

export const useCampaignsStore = defineStore({
	id: 'campaigns',
	state: () => {
		return {
			// initialize state from local storage to enable user to stay logged in
			campaignlist: JSON.parse(localStorage.getItem("campaignlist")) || [],
		}
	},
	getters: {
	},
	actions: {
		async campaignsGetShortlist(force = false) {
			if(localStorage.getItem("campaignlist") && force == false) {
				return true;
			}
			try {
				const resp = await API.get("/v1/campaigns/shortlistforaction")
				this.campaignlist = resp.data.campaigns;
				localStorage.setItem("campaignlist", JSON.stringify(this.campaignlist));
				return resp;

			}
			catch(err) {
				console.error('ERROR ', err.message)
				return {'Error': err.message}
			}

		}
	},
});
