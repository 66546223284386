import { defineStore } from 'pinia';
import API from "../js/backend/axios";

export const useListsStore = defineStore({
	id: 'lists',
	state: () => {
		return {
			// initialize state from local storage to enable user to stay logged in
			listslist: JSON.parse(localStorage.getItem("listslist")) || [],
			list_api_ids: JSON.parse(localStorage.getItem("listapi_ids")) || [],
			listslistwithids: JSON.parse(localStorage.getItem("listslistwithids")) || [],
		}
	},
	getters: {
	},
	actions: {
		async listsGetShortlist(force = false) {
			if(localStorage.getItem("listslist") && force == false) {
				return true;
			}
			try {
				const resp = await API.get("/v1/lists/shortlist")
				this.listslist = resp.data.lists;
				localStorage.setItem("listslist", JSON.stringify(this.listslist));
				return resp;
			}
			catch(err) {
				console.error('ERROR ', err.message)
				return {'Error': err.message}
			}
		},
		async listsGetShortlistWithId(force = false) {
			if(localStorage.getItem("listslistwithids") && force == false) {
				return true;
			}
			try {
				const resp = await API.get("/v1/lists/shortlist?withids=1")
				this.listslistwithids = resp.data.lists;
				console.log('lists from store',resp.data.lists)
				localStorage.setItem("listslistwithids", JSON.stringify(this.listslistwithids));
				return resp;

			}
			catch(err) {
				console.error('ERROR ', err.message)
				return {'Error': err.message}
			}
		},
		async listsgetApiIds(force = false) {
			if(localStorage.getItem("list_api_ids") && force == false) {
				return true;
			}
			try {
				const resp = await API.get("/v1/lists/getapiids")
				this.list_api_ids = resp.data.list_api_ids;
				localStorage.setItem("list_api_ids", JSON.stringify(this.list_api_ids));
				return resp;

			}
			catch(err) {
				console.error('ERROR ', err.message)
				return {'Error': err.message}
			}
		},
	}
});
