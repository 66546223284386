<template>
	<div class="container-fluid site">
		<div class="row">
			<div class="col-sm-12">
				<router-view/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Mailings",

	setup(props, { emit }) {
	}
}
</script>
