// import Home from "./views/Home.vue"

// import DomainsContacts from "./components/Domains/Contacts.vue"
import Login from "./components/Login.vue";
import Dashboard from "./components/Dashboard.vue";
import System from "./Layouts/SystemLayout.vue";
import Tools from "./Layouts/ToolsLayout.vue";
import Blacklists from "./Layouts/BlacklistsLayout.vue";
import Clients from "./Layouts/ClientsLayout.vue";
import Campaigns from "./Layouts/CampaignsLayout.vue";
import Actions from "./Layouts/ActionsLayout.vue";
import Mailings from "./Layouts/MailingsLayout.vue";
import Stats from "./Layouts/StatsLayout.vue";
import NotFound from "./components/NotFound.vue";
import VueJwtDecode from "vue-jwt-decode";
import dayjs from 'dayjs';
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

import { useAuthStore } from '@/store';
import { useMiscStore } from '@/store';
import { createWebHistory, createRouter } from "vue-router";

const defaultTitle = "HelloMail Administration";

const getLogin = (to, from, next) => {
	const authStore = useAuthStore();
	if (
		!localStorage.getItem("HMadminToken") ||
		localStorage.getItem("HMadminToken") == ""
	) {
		console.log("log me in");
		next("/login");
		return;
	}

	// console.log("log me not in " + localStorage.getItem("HMadminToken"))
	const decodedToken = VueJwtDecode.decode(localStorage.getItem("HMadminToken"));
	if (!decodedToken || !decodedToken.exp || !decodedToken.context) {
		next("/login");
		return;
	}
	let jetzt = Math.floor(Date.now() / 1000);
	if (jetzt > parseInt(decodedToken.exp)) {
		next("/login");
		return;
	}
	localStorage.setItem("userData", JSON.stringify(decodedToken.context.user));
	const exp = parseInt(decodedToken.exp);
	let now = dayjs().unix()
	if (exp < now) {
		authStore.authRefresh()
			.then((res) => {
				console.log(res)
			})
			.catch(err => {
				console.error('ERRRR ', err)
			})
	}
	next();
	return;
};

/** @type {import("vue-router").RouterOptions["routes"]} */
const routes = [
	{
		path: "/",
		redirect: "/dashboard",
		meta: { title: defaultTitle },
	},
	{
		path: "/dashboard",
		// meta: { title: "PBN", navtitle: "PBN" },
		meta: { title: defaultTitle },
		name: "Dashboard",
		component: Dashboard,
		beforeEnter: getLogin,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: '/actions',
		meta: { title: 'Aktionen', navtitle: 'Aktionen' },
		name: "Aktionen",
		component: Actions,
		beforeEnter: getLogin,
		redirect: '/actions/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'Aktionsverwaltung' },
				path: 'list',
				name: "Aktionsverwaltung",
				component: () => import(/* webpackChunkName: "actions" */ "./components/Actions/Actions.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Aktionsverwaltung v1' },
				path: 'listv1',
				name: "Aktionsverwaltungv1",
				component: () => import(/* webpackChunkName: "actions" */ "./components/Actions/Actionsv1.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Aktion Details' },
				path: 'single/:id',
				name: "Aktion Details",
				component: () => import(/* webpackChunkName: "actions" */ "./components/Actions/Actions.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Neue Aktion', tab: 'Add' },
				path: 'add',
				name: "Neue Aktion",
				component: () => import(/* webpackChunkName: "actions" */ "./components/Actions/Actions.vue"),
				beforeEnter: getLogin,
			},
		]
	},
	{
		path: '/blacklists',
		meta: { title: 'Blacklists', navtitle: 'Blacklists' },
		name: "Blacklists",
		component: Blacklists,
		beforeEnter: getLogin,
		redirect: '/blacklists/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'Blacklists' },
				path: 'list',
				name: "Blacklists",
				component: () => import(/* webpackChunkName: "campaigns" */ "./components/Blacklists/Blacklists.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Blacklist Details' },
				path: ':type/single/:id',
				name: "Blacklist Details",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Blacklists/Blacklists.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Neuer Blacklist', tab: 'Add' },
				path: ':type/add',
				name: "Neue Blacklist",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Blacklists/Blacklists.vue"),
				beforeEnter: getLogin,
			},
		]
	},
	{
		path: '/campaigns',
		meta: { title: 'Kampagnen', navtitle: 'Kampagnen' },
		name: "Kampagnen",
		component: Campaigns,
		beforeEnter: getLogin,
		redirect: '/campaigns/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'Kampagnenverwaltung' },
				path: 'list',
				name: "Kampagnenverwaltung",
				component: () => import(/* webpackChunkName: "campaigns" */ "./components/Campaigns/Campaigns.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Kampagnen Details' },
				path: 'single/:id',
				name: "Kampagnen Details",
				component: () => import(/* webpackChunkName: "campaigns" */ "./components/Campaigns/Campaigns.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Neue Kampagne', tab: 'Add' },
				path: 'add',
				name: "Neue Kampagne",
				component: () => import(/* webpackChunkName: "campaigns" */ "./components/Campaigns/Campaigns.vue"),
				beforeEnter: getLogin,
			},
		]
	},
	{
		path: '/clients',
		meta: { title: 'Kunden', navtitle: 'Kunden' },
		name: "Kunden",
		component: Clients,
		beforeEnter: getLogin,
		redirect: '/clients/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'Kundenverwaltung' },
				path: 'list',
				name: "Kundenverwaltung",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Clients/Clients.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Kunden Details' },
				path: 'single/:id',
				name: "Kunden Details",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Clients/Clients.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Neuer Kunde', tab: 'Add' },
				path: 'add',
				name: "Neuer Kunde",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Clients/Clients.vue"),
				beforeEnter: getLogin,
			},
		]
	},
	{
		path: '/mailings',
		meta: { title: 'Mailings', navtitle: 'Mailings' },
		name: "Mailings",
		component: Mailings,
		beforeEnter: getLogin,
		redirect: '/mailings/list',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'Mailingverwaltung' },
				path: 'list',
				name: "Mailingverwaltung",
				component: () => import(/* webpackChunkName: "mailings" */ "./components/Mailings/Mailings.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Mailing Details' },
				path: 'single/:id',
				name: "Mailing Details",
				component: () => import(/* webpackChunkName: "mailings" */ "./components/Mailings/Mailings.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Neues Mailing', tab: 'Add' },
				path: 'add',
				name: "Neues Mailing",
				component: () => import(/* webpackChunkName: "mailings" */ "./components/Mailings/Mailings.vue"),
				beforeEnter: getLogin,
			},
		]
	},

	{
		path: '/stats',
		meta: { title: 'Stats', navtitle: 'Stats' },
		name: "Stats",
		component: Stats,
		beforeEnter: getLogin,
		redirect: '/stats/campaigns/reporting',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'Mailing Stats' },
				path: 'mailings',
				name: "MailingStats",
				component: () => import(/* webpackChunkName: "mailings" */ "./components/Stats/MailingStats.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Campaign Stats' },
				path: 'campaigns',
				name: "CampaignStats",
				component: () => import(/* webpackChunkName: "campaigns" */ "./components/Stats/CampaignStats.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Campaign Stats' },
				path: 'campaignlists',
				name: "CampaignListStats",
				component: () => import(/* webpackChunkName: "campaignlists" */ "./components/Stats/CampaignListStats.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Gender Performance' },
				path: 'genderstats',
				name: "GenderPerformance",
				component: () => import(/* webpackChunkName: "genderstats" */ "./components/Stats/GenderStats.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Domain Performance' },
				path: 'domainstats',
				name: "DomainPerformance",
				component: () => import(/* webpackChunkName: "genderstats" */ "./components/Stats/DomainStats.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Kampagnen Reporting' },
				path: 'campaigns/reporting',
				name: "KampagnenReporting",
				component: () => import(/* webpackChunkName: "campaignreporting" */ "./components/Stats/CampaignReporting.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Publisher Stats' },
				path: 'publisherstats',
				name: "PublisherStats",
				component: () => import(/* webpackChunkName: "publisherstats" */ "./components/Stats/PublisherStats.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Inventar Stats' },
				path: 'user_inventory',
				name: "InventoryStats",
				component: () => import(/* webpackChunkName: "publisherstats" */ "./components/Stats/InventoryStats.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'User Teilnahmen' },
				path: 'user_participations',
				name: "UserParticipations",
				component: () => import(/* webpackChunkName: "publisherstats" */ "./components/Stats/UserParticipations.vue"),
				beforeEnter: getLogin,
			},
		]
	},

	{
		path: '/system',
		meta: { title: 'System', navtitle: 'System' },
		name: "System",
		component: System,
		beforeEnter: getLogin,
		redirect: '/system/jobqueue',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'Userverwaltung' },
				path: 'users/list',
				name: "Userverwaltung",
				component: () => import(/* webpackChunkName: "users" */ "./components/System/Users.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'User Details' },
				path: 'users/single/:id',
				name: "User Single",
				component: () => import(/* webpackChunkName: "users" */ "./components/System/Users.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'User Add', tab: 'Add' },
				path: 'users/add',
				name: "User Add",
				component: () => import(/* webpackChunkName: "users" */ "./components/System/Users.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Mailservers' },
				path: 'mailservers/list',
				name: "Mailservers",
				component: () => import(/* webpackChunkName: "users" */ "./components/System/Mailservers.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Mailer Details' },
				path: 'mailservers/single/:id',
				name: "Mailer Single",
				component: () => import(/* webpackChunkName: "users" */ "./components/System/Mailservers.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Mailer Add', tab: 'Add' },
				path: 'mailservers/add',
				name: "Mailer Add",
				component: () => import(/* webpackChunkName: "users" */ "./components/System/Mailservers.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Parserservers' },
				path: 'parsers',
				name: "Parserservers",
				component: () => import(/* webpackChunkName: "users" */ "./components/System/ParserServer.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Mailqueue' },
				path: 'mailqueue',
				name: "Mailqueue",
				component: () => import(/* webpackChunkName: "users" */ "./components/System/MailQueue.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Job Queue' },
				path: 'jobqueue',
				name: "JobQueue",
				component: Dashboard,
				beforeEnter: getLogin,
			},
		]
	},

	{
		path: '/tools',
		meta: { title: 'Tools', navtitle: 'Tools' },
		name: "Tools",
		component: Tools,
		beforeEnter: getLogin,
		redirect: '/tools/lists',
		// example of route level code-splitting
		// this generates a separate chunk (About.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// component: () => import('./views/About.vue')
		children: [
			{
				meta: { title: 'Kategorien' },
				path: 'categories',
				name: "Kategorien",
				component: () => import(/* webpackChunkName: "users" */ "./components/Tools/Categories.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Listen' },
				path: 'lists',
				name: "Listen",
				component: () => import(/* webpackChunkName: "users" */ "./components/Tools/Lists.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Listen Details' },
				path: 'lists/single/:id',
				name: "Listen Details",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Tools/Lists.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Neue Liste', tab: 'Add' },
				path: 'lists/add',
				name: "Neue Liste",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Tools/Lists.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Testempfänger' },
				path: 'testrecipients',
				name: "Testempfänger",
				component: () => import(/* webpackChunkName: "users" */ "./components/Tools/Testrecipients.vue"),
				beforeEnter: getLogin,
			},			{
				meta: { title: 'Testempfänger Details' },
				path: 'testrecipients/single/:id',
				name: "Testempfänger Details",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Tools/Testrecipients.vue"),
				beforeEnter: getLogin,
			},
			{
				meta: { title: 'Neuer Testempfänger', tab: 'Add' },
				path: 'testrecipients/add',
				name: "Neuer Testempfänger",
				component: () => import(/* webpackChunkName: "clients" */ "./components/Tools/Testrecipients.vue"),
				beforeEnter: getLogin,
			},
		]
	},

	{ path: "/:path(.*)", component: NotFound },
];

const router = createRouter({
	// 4. Provide the history implementation to use. We are using the hash history for simplicity here.
	history: createWebHistory(),
	routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title || defaultTitle;
	next();
});

router.afterEach((to, from) => {
  const store = useMiscStore()
	// if(from === "x") console.log( from);
	store.miscSetActiveNav(to.meta.navtitle)
});

export default router;
