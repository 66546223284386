// eslint-disable-next-line
import API from "./axios";
export default {
	// eslint-disable-next-line
	getActionList(params) {
		return API.get("/v1/actions/list",{params: params});
	},
	getActionListv1(params) {
		return API.get("/v1/actions/listv1",{params: params});
	},
	getActionShortList(search) {
		return API.get(`/v1/actions/shortlist/${search}`);
	},
	getAction(id) {
		return API.get("/v1/actions/single/" + id);
	},
	getSelectionAmount(id, data, blacklists) {
		return API.post("/v1/actions/getamount/" + id, {"search":data, "blacklists": blacklists});
	},
	getBlacklistsSelecter(campaignid) {
		return API.get("/v1/actions/blacklistsselecter/" + campaignid);
	},
	editAction(data) {
		return API.post("/v1/actions/edit", data);
	},
	addAction(data) {
		return API.post("/v1/actions/add", data);
	},
	deleteAction(hid) {
		return API.post("/v1/actions/delete", {id: hid});
	},
	getTemplate(acid) {
		return API.get("/v1/actions/templates/" + acid);
	},
	getTemplateInfo(acid) {
		return API.get("/v1/actions/templates/info/" + acid);
	},
	getTemplateShortlist(search) {
		return API.get("/v1/actions/templates/shortlist/"+ search);
	},
	editTemplate(data) {
		return API.post("/v1/actions/templates/edit", data);
	},
	addTemplate(data) {
		return API.post("/v1/actions/templates/add", data);
	},
	getReleaseRecipients(acid) {
		return API.get("/v1/actions/getreleaserecipients/" + acid);
	},
	getControlRecipients(acid,listid) {
		return API.get("/v1/actions/getcontrolrecipients/" + acid + "/" + listid);
	},
	getUploadedImages(acid) {
		return API.get("/v1/actions/getuploadedimages/" + acid);
	},
	setRemoteImages(acid,imglist) {
		return API.post("/v1/actions/setremoteimages", {"acid":acid, "sourceimages": imglist});
	},
	getActionsForCampaign(caid) {
		return API.get(`/v1/actions/bycaid/${caid}`);
	},
	sendTestMail(acid,testrecipients) {
		return API.post("/v1/actions/sendtestmail", { acid: acid, recipients: testrecipients});
	},
	getSendSuccess(acid) {
		return API.get(`/v1/actions/getsendsuccess/${acid}`)
	}
};
