<template>
	<div id="loginpage">
		<form class="login" @submit.prevent="login">
			<div class="">
				<div id="loginform">
					<div id="logoblock">
						<div id="logoemblem" :style="`background-image: url('${backgroundImage}')`">
						</div>
						<!-- {{ status }} -->
					</div>
					<div class="container-fluid">
						<div class="msg" v-if="error">{{ error }}</div>
						<div class="row">
							<input
								class="form-control"
								required
								v-model="username"
								type="text"
								placeholder="Login"
							/>
						</div>
						<div class="row">
							<input
								class="form-control"
								required
								v-model="password"
								type="password"
								placeholder="Password"
							/>
						</div>
						<div class="row">
							<button
								class="thebutton btn btn-primary btn-sm text-light btn-lg btn-block uitest-login-action"
								type="submit"
							>
								Log in
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script src="../js/Login.js"></script>
