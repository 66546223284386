import { defineStore } from 'pinia';
import API from "../js/backend/axios";

export const useMiscStore = defineStore({
	id: 'misc',
	state: () => {
		return {
			// initialize state from local storage to enable user to stay logged in
			currentTitle: JSON.parse(localStorage.getItem("currentTitle")) || {},
			currentUrl: '/',
			activenav: 'home',
			currentLang: localStorage.getItem("currentLang") || 'en',
			lastPage: localStorage.getItem('lastPage') || '/',
			resources: JSON.parse(localStorage.getItem("resources")) || [],
			categories: JSON.parse(localStorage.getItem("categories")) || [],
		}
	},
	getters: {
		// currentUrl: state => state.currentUrl,
		// currentTitle: state => state.currentTitle
	},
	actions: {
		async miscSetUrl(url) {
			this.currentUrl = url
		},
		async miscSetLang(lang) {
			localStorage.setItem("currentLang", lang)
			this.currentLang = lang
		},
		async miscSetActiveNav(nav) {
			this.activenav = nav
		},
		async miscSetTile(title) {
			this.currentTitle = title
		},
		async miscSetLastPage(url) {
			localStorage.setItem("lastPage", url)
			this.lastPage = url
		},
		async miscGetResources(force = false) {
			if(localStorage.getItem("resources") && force == false) return true;
			try {
				const resp = await API.get("/v1/users/getresources")
				console.log(resp)
				this.resources = resp.data.resources;
				localStorage.setItem("resources", JSON.stringify(this.resources));
				return resp;

			}
			catch(err) {
				console.error('ERROR ', err.message)
				return {'Error': err.message}
			}
		},
		async miscGetCategories(force = false) {
			if(localStorage.getItem("categories") && force == false) return true;
			try {
				const resp = await API.get("/v1/categories/shortlist")
				this.categories = resp.data.categories;
				localStorage.setItem("categories", JSON.stringify(this.categories));
				return resp;

			}
			catch(err) {
				console.error('ERROR ', err.message)
				return {'Error': err.message}
			}
		}
	}
});
