import { defineStore } from 'pinia';
import API from "../js/backend/axios";

export const useClientsStore = defineStore({
	id: 'clients',
	state: () => {
		return {
			// initialize state from local storage to enable user to stay logged in
			clientlist: JSON.parse(localStorage.getItem("clientlist")) || [],
		}
	},
	getters: {
	},
	actions: {
		async clientsGetShortlist(force = false) {
			if(localStorage.getItem("clientlist") && force == false) {
				return true;
			}
			try {
				const resp = await API.get("/v1/clients/shortlist")
				this.clientlist = resp.data.clients;
				localStorage.setItem("clientlist", JSON.stringify(this.clientlist));
				return resp;
			}
			catch(err) {
				console.error('ERROR ', err.message)
				return {'Error': err.message}
			}

		}
	}
});
