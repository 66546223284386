import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from 'vue-i18n'
import router from "./router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Toaster from "@meforma/vue-toaster";
import PrimeVue from 'primevue/config';
import DataView from 'primevue/dataview';
import Dropdown from 'primevue/dropdown';
import Sidebar from 'primevue/sidebar';
import Tooltip from 'primevue/tooltip';

import { createPinia } from "pinia";
import "bootstrap";
// import dayjs from "dayjs"
import * as dayjs from "dayjs";
import API from "./js/backend/axios";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faArrowRight, faArrowUp, faChartLine, faChartPie, faCircleChevronUp, faCogs, faCubes, faCommentMedical,
	faCommentSlash, faEnvelopeOpenText, faEraser, faExclamationTriangle,
	faEye, faFile, faFileCsv, faFileSignature, faFilter, faGlobe,
	faInbox, faLink, faList, faLock, faMailBulk, faMinusCircle,
	faPalette, faPeopleArrows, faPlusCircle, faRemoveFormat, faScroll,
	faSignOutAlt, faSitemap, faSkullCrossbones, faSpinner, faThumbsDown, faTools,
	faUser, faUsers, faUserCog, faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {
	faComments, faFileAlt, faFilePdf, faNewspaper, faQuestionCircle,
	faPaperPlane, faUser as faUserOutline
} from "@fortawesome/free-regular-svg-icons";
library.add(
	faArrowRight, faArrowUp, faChartLine, faChartPie, faCircleChevronUp, faCogs, faCommentMedical,
	faComments, faCommentSlash, faCubes, faEnvelopeOpenText,
	faExclamationTriangle,
	faEraser,faEye, faFile, faFileCsv, faFileSignature, faFileAlt,
	faFilePdf, faFilter, faGlobe, faInbox, faLink, faList, faLock, faMailBulk,
	faMinusCircle, faNewspaper,
	faPlusCircle, faPalette, faPaperPlane, faPeopleArrows, faQuestionCircle, faRemoveFormat,
	faScroll, faSignOutAlt, faSitemap, faSkullCrossbones, faSpinner,
	faThumbsDown, faTools, faUser,
	faUserCog, faUserOutline, faUsers, faUserTie);

const numberFormats = {
	"en-US": {
		currency: {
			style: "currency",
			currency: "USD",
			notation: "standard",
		},
		decimal: {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		},
		percent: {
			style: "percent",
			useGrouping: false,
		},
	},
	"de-DE": {
		currency: {
			style: "currency",
			currency: "EUR",
			useGrouping: true,
			currencyDisplay: "symbol",
		},
		decimal: {
			style: "decimal",
			minimumSignificantDigits: 3,
			maximumSignificantDigits: 5,
		},
		percent: {
			style: "percent",
			useGrouping: false
		},
	},
};

const i18n = createI18n({
	legacy: false, // you must set `false`, to use Composition API
	locale: 'ja', // set locale
	fallbackLocale: 'en', // set fallback locale
	scope: 'global',
	numberFormats,
	globalInjection: true
})

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component('DataView', DataView);
app.component('Dropdown', Dropdown);
app.component('Sidebar', Sidebar);
app.directive('tooltip', Tooltip);

app.use(Toaster, {
	// One of the options
	position: "top",
});
app.use(i18n)
app.use(router);
const pinia = createPinia()
app.use(pinia)
app.use(PrimeVue);
app.mount("#app");
