import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/store';
import { createToaster } from "@meforma/vue-toaster";
import { useRouter, useRoute } from 'vue-router'
import { useListsStore, useClientsStore } from "@/store";
import { ref, reactive, computed, watch, onBeforeMount, onMounted, defineProps } from "vue";

import "@/assets/scss/login.scss";

export default {
	setup(props, { emit }) {
		const authStore = useAuthStore();
		const clientsStore = useClientsStore();
		const listsStore = useListsStore();
		const error = "";
		const toaster = createToaster({ position: 'top' });
		const router = useRouter()
		const	username = ref("");
		const	password = ref("");
		const { loggedin, status, userdata } = storeToRefs(useAuthStore())
		const backgroundImage = process.env.VUE_APP_LOGO;




		const login = () => {
			console.log("LOG IN WITH ",username.value )
			let usererror = "";
			authStore.authRequest({
				username: username.value,
				password: password.value
			})
			.then(result => {

				console.log(result)
				if (result.Error) {
					usererror = result.Error;
						// console.error(result.loginError);
				}
				else {
					if (localStorage.getItem("userData") == "") {
						usererror = "No matching user data available";
					}
				}
				if (usererror != "") {
					toaster.error("Error<br/>" + usererror)
				}
				else {
					// load fresh data into storgae on login
					clientsStore.clientsGetShortlist(true);
					listsStore.listsGetShortlist(true);
					listsStore.listsGetShortlistWithId(true);
					let startpage = localStorage.getItem("lastUrl") || "/";
					startpage = "/";
					toaster.success("Logged in")
					// console.log("DRIN")
					// console.log(localStorage.getItem('userData'))
					router.push(startpage);
					// that.$toastr.Add({
						// 	title: "Hooray.", // Toast Title
						// 	msg: "Logged in",
						// 	position: "toast-top-center",
						// 	type: "success"
						// });
						// error = "";
					}
				})
				.catch(function (error) {
					console.log("ERRRRRR", error)
					let failtxt;

					if (error.status) {
						let perr = parseInt(error.status);
						if (perr == 400 || perr == 401) {
							failtxt = "No access with this data";
						}
						else {
							if (error.data.error != "" && typeof (error.data.error) != 'undefined') {
								failtxt = error.data.error;
							}
							else {
								failtxt = "Server error";
							}
						}
					}
					else {
						failtxt = "Login failed.";
					}
					// console.error("hier auch nicht");
					toaster.error(failtxt, error);

				});
		}

		return {
			backgroundImage,
			error,
			login,
			username,
			password
		}
	}
};
