import { defineStore } from 'pinia';
import API from "../js/backend/axios";

export const useMailserversStore = defineStore({
	id: 'mailservers',
	state: () => {
		return {
			// initialize state from local storage to enable user to stay logged in
			masterlist: JSON.parse(localStorage.getItem("masterlist")) || {},
		}
	},
	getters: {
	},
	actions: {
		setMasterList(allservers) {
			var ml = allservers.filter(function (el) {
				return el.MS_MASTER == 0;
			});
			var mxl = ml.map(function (el) {
				return {'value': el.MS_ID, 'text': el.MS_NAME }
			});
			mxl.unshift({'value': 0,'text': 'I am Master!'})
			localStorage.setItem("masterlist", JSON.stringify(mxl));
		},

	}
});
