<template>
	<div class="mh-100" style="height:100vh">
		<Menu v-if="authStore.loggedin" @onLogOut="logout" :count="counter" />
		<main style="min-height:100%">
			<router-view />
		</main>
	</div>
</template>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
<style src="@vueform/toggle/themes/default.css"></style>

<script>

import { createToaster } from "@meforma/vue-toaster";
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { ref, reactive, computed, watch, onBeforeMount, onMounted, defineProps } from "vue";
import { useAuthStore } from '@/store';
import Menu from './components/Menu.vue';
export default {

	components: {
		Menu
	},

	setup(props, { emit }) {
		const authStore = useAuthStore();
		const router = useRouter()
		const counter = ref(60*60*24);
		const toaster = createToaster({ position: 'top' });
		const logout = () => {
			authStore.authLogout();
			let startpage = "/login";
			toaster.success("Logged out");
			router.push(startpage);
		};

		const countDownTimer = () => {
			if (counter.value > 0) {
				setTimeout(() => {
					counter.value -= 1;
					countDownTimer();
				}, 1000);
			}
			else {
				logout();
			}
		};

		return {
			logout,
			counter,
			authStore
		}
	}
}
</script>
