<template>
	<nav class="navbar navbar-expand-lg bg-light navbar-light" variant="light">
		<div class="container-fluid">
			<router-link class="navbar-brand" to="/">
				<img
					class="navimg"
					style="height:70px; width:160px"
					:src="logopic"
				/>
			</router-link>
			<button
				class="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li v-for="(item, idx) in MenuItems" v-bind:key="idx" :class="{'nav-item': true, 'dropdown': item.Submenu}">
						<a
							v-if="item.Submenu"
							:class="{
								'nav-link': true,
								'dropdown-toggle': true,
								'active': activenav === item.name,
							}"
							href="#"
							id="navbarDropdown"
							role="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<span class="icon">
								<font-awesome-icon
									:icon="item.icon"
									class="menu__icon"
									size="lg"
								/>
							</span>
							{{item.Title}}
						</a>
						<router-link v-else
							class="nav-link iconitem"
							aria-current="mailings"
							:to="item.href"
						>
							<span class="icon">
								<font-awesome-icon :icon="item.icon" class="menu__icon" size="lg" />
							</span>
							{{item.Title}}
						</router-link>
						<ul v-if="item.Submenu" class="dropdown-menu" aria-labelledby="mailingDropdown">
							<li v-for="(subitem, id) in item.Submenu" v-bind:key="id">
								<router-link
									class="dropdown-item iconitem"
									aria-current="mailings"
									:to="subitem.href"
								>
									<span class="icon">
										<font-awesome-icon :icon="subitem.icon" class="menu__icon" size="lg" />
									</span>
									{{subitem.Title}}
								</router-link>
							</li>
						</ul>

					</li>
				</ul>


				<ul class="navbar-nav ml-auto">

					<li class="nav-item">
						<router-link to="#" @click="signout" class="dropdown-item iconitem">
							<span class="icon alarm">
								<font-awesome-icon :icon="['fas', 'sign-out-alt']" class="menu__icon" size="xl" />
							</span>
						</router-link>

					</li>


				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
import { useMiscStore } from '@/store';
import { ref, reactive, computed, watch, onBeforeMount, onMounted } from "vue";

export default {

	setup(props, { emit }) {
		const logopic = process.env.VUE_APP_LOGO;
console.log(process.env.VUE_APP_LOGO)
		const miscStore = useMiscStore();
		// console.log(miscStore.currentLang)
		const active = ref("home")
		const	activenav = miscStore.activenav
		const currentLang = miscStore.currentLang;

		const MenuItems = [
			{
				'name': 'Clients',
				'Title': 'Kunden',
				'href': '/clients/list',
				'icon': ['fas', 'user-tie']/*,
				'Submenu': [
					{
						'href': "/clients/list",
						'Title': 'Suche',
						'icon': ['fas', 'list'],
					},
					{
						'href': "/blacklists/list/client",
						'Title': 'Blacklists',
						'icon': ['fas', 'eraser'],
					}
				]*/
			},
			{
				'name': 'Campaigns',
				'Title': 'Kampagnen',
				'href': '/campaigns/list',
				'icon': ['fas', 'globe']/*,
				'Submenu': [
					{
						'href': "/campaigns/list",
						'Title': 'Suche',
						'icon': ['fas', 'list'],
					},
					{
						'href': "/blacklists/list/campaign",
						'Title': 'Blacklists',
						'icon': ['fas', 'eraser'],
					}
				]*/
			},
			{
				'name': 'Blacklists',
				'Title': 'Blacklists',
				'href': '/blacklists/list',
				'icon': ['fas', 'eraser']/*,
				'Submenu': [
					{
						'href': "/",
						'Title': 'Suche',
						'icon': ['fas', 'list'],
					},
					// {
					// 	'href': "/mailings/bounces/prefilters",
					// 	'Title': 'Bounce Prefilters',
					// 	'icon': ['fas', 'comment-slash'],
					// },
					// {
					// 	'href': "/mailings/bounces/filters",
					// 	'Title': 'BounceFilter setzen',
					// 	'icon': ['fas', 'comment-slash'],
					// },
					// {
					// 	'href': "/mailings/bounces/bouncer_csv",
					// 	'Title': 'Bouncer CSV',
					// 	'icon': ['fas', 'comment-slash'],
					// },
					// {
					// 	'href': "/mailings/revoke",
					// 	'Title': 'Mailing zurücksetzen',
					// 	'icon': ['fas', 'remove-format'],
					// },
					// {
					// 	'href': "/mailings/openers",
					// 	'Title': 'Reagierer Mailing',
					// 	'icon': ['fas', 'comment-medical'],
					// },
				]*/
			},
			{
				'name': 'Actions',
				'Title': 'Aktionen',
				'href': '#',
				'icon': ['fas', 'filter'],
				'Submenu': [
					{
						'href': "/actions/list",
						'Title': 'Suche',
						'icon': ['fas', 'list'],
					},
					{
						'href': "/actions/add",
						'Title': 'Neu',
						'icon': ['fas', 'plus-circle'],
					},
					{
						'href': "/mailings/list",
						'Title': 'Mailings',
						'icon': ['fas', 'envelope-open-text'],
					},
				]
			},
			{
				'name': 'Statistics',
				'Title': 'Statistik',
				'href': '#',
				'icon': ['fas', 'chart-line'],
				'Submenu': [
					{
						'href': "/stats/campaigns/reporting",
						'Title': 'Kampagnen Abrechnung',
						'icon': ['fas', 'chart-pie'],
					},
					{
						'href': "/stats/user_inventory",
						'Title': 'Bestandshistorie',
						'icon': ['fas', 'users'],
					},
					{
						'href': "/stats/mailings",
						'Title': 'Mailing Stats',
						'icon': ['fas', 'mail-bulk'],
					},
					{
						'href': "/stats/campaigns",
						'Title': 'Campaign Stats',
						'icon': ['fas', 'chart-pie'],
					},
					{
						'href': "/stats/campaignlists",
						'Title': 'Campaign/List Stats',
						'icon': ['fas', 'chart-pie'],
					},
					{
						'href': "/stats/publisherstats",
						'Title': 'Publisher Performance',
						'icon': ['fas', 'scroll'],
					},
					{
						'href': "/stats/genderstats",
						'Title': 'Gender Performance',
						'icon': ['fas', 'people-arrows'],
					},
					{
						'href': "/stats/domainstats",
						'Title': 'Domain Stats',
						'icon': ['fas', 'link'],
					},
					{
						'href': "/stats/user_participations",
						'Title': 'User Teilnahmen',
						'icon': ['far', 'comments'],
					},
				]
			},

			{
				'name': 'Tools',
				'Title': 'Tools',
				'href': '#',
				'icon': ['fas', 'tools'],
				'Submenu': [
					/*
					{
						'href': "/tools/ipmanagement",
						'Title': 'IP Management',
						'icon': ['fas', 'sitemap'],
					},*/
					{
						'href': "/tools/testrecipients",
						'Title': 'Testempfänger',
						'icon': ['fas', 'inbox'],
					},
					{
						'href': "/tools/lists",
						'Title': 'Listen',
						'icon': ['fas', 'chart-pie'],
					},
					{
						'href': "/tools/categories",
						'Title': 'Kategorien',
						'icon': ['fas', 'list'],
					},
				]
			},

			{
				'name': 'System',
				'Title': 'System',
				'href': '#',
				'icon': ['fas', 'cogs'],
				'Submenu': [
					{
						'href': "/system/users/list",
						'Title': 'Userverwaltung',
						'icon': ['fas', 'users'],
					},
					{
						'href': "/system/jobqueue",
						'Title': 'Job Queue',
						'icon': ['fas', 'cubes'],
					},
					{
						'href': "/system/mailservers/list",
						'Title': 'Mailserver',
						'icon': ['fas', 'mail-bulk'],
					},
					{
						'href': "/system/mailqueue",
						'Title': 'Mail Queue',
						'icon': ['fas', 'inbox'],
					},
					{
						'href': "/system/parsers",
						'Title': 'Parser Systeme',
						'icon': ['far', 'newspaper'],
					},
					{
						'href': "/system/logs",
						'Title': 'Log Files',
						'icon': ['fas', 'file-signature'],
					},
				]
			},

		]

		const signout = () => {
			emit("onLogOut");
		}
		return {
			logopic,
			signout,
			currentLang,
			activenav,
			MenuItems
		}

	}

};
</script>
