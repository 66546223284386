import { defineStore } from 'pinia';
import ActionSource from '@/js/backend/actions';
// import API from "../js/backend/axios";

export const useActionsStore = defineStore({
	id: 'actions',
	state: () => {
		return {
			// initialize state from local storage to enable user to stay logged in
			actionslist: JSON.parse(localStorage.getItem("actionslist")) || {},
		}
	},
	getters: {
	},
	actions: {
		async actionsGetShortlist(force = false) {
			if(localStorage.getItem("actionslist") && force == false) {
				return true;
			}
			try {
				const resp = await ActionSource.getActionShortList(0)
				// const resp = await API.get("/v1/actions/shortlist/0")
				this.actionslist = resp.data.actions;
				localStorage.setItem("actionslist", JSON.stringify(this.actionslist));
				return resp;

			}
			catch(err) {
				console.error('ERROR ', err.message)
				return {'Error': err.message}
			}

		}
	}
});
